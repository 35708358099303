/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { each, isEmpty, keyBy, map, values } from 'lodash-es';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { CLARITY_SIGNPOST_POSITION, OrgTreeNode } from '@ws1c/intelligence-models';

interface TreeNode {
  id: number;
  text: string;
  children: TreeNode[];
}

/**
 * OrganizationGroupSelectorComponent
 * dislays OG hierarchy
 * @export
 * @class OrganizationGroupSelectorComponent
 */
@Component({
  selector: 'dpa-organization-group-selector',
  templateUrl: 'organization-group-selector.component.html',
  styleUrls: ['organization-group-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IntelligenceCommonModule],
})
export class OrganizationGroupSelectorComponent implements OnChanges {
  @Input() public selectedValues?: number[] = [];
  @Input() public orgHierarchy: OrgTreeNode;
  @Input() public singleSelection?: boolean = false;
  @Input() public dropdownAlignRight?: boolean = false;
  @Input() public recursiveChildSelection?: boolean = false;
  @Input() public recursiveParentSelection?: boolean = false;
  @Input() public showSelectedOrgs?: boolean = true;
  @Output() public onDone = new EventEmitter<any[]>();

  public isOpen: boolean = false;
  public selectedValuesIndex = {};
  public treeNodesById = {};
  public treeRoot: any = {};
  public filteredTreeRoot: any = {};
  public query: string = '';
  public selectedIds: string[] = [];
  public SIGNPOST_POSITION = CLARITY_SIGNPOST_POSITION.BOTTOM_LEFT;
  public readonly MIN_CHAR_FOR_SEARCH = 2;

  /**
   * Creates an instance of OrganizationGroupSelectorComponent.
   * @memberof OrganizationGroupSelectorComponent
   */
  constructor() {
    this.onItemSelectedChange = this.onItemSelectedChange.bind(this);
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes [description]
   * @memberof OrganizationGroupSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedValues) {
      this.selectedValuesIndex = keyBy(changes.selectedValues.currentValue);
    }
    if (changes.orgHierarchy) {
      this.treeRoot = this.convertTree(changes.orgHierarchy.currentValue || {});
      this.treeRoot.expanded = true;
      this.treeNodesById = this.getTreeNodesById(this.treeRoot);
    }
  }

  /**
   * onInputFocus
   * @memberof OrganizationGroupSelectorComponent
   */
  public onInputFocus() {
    this.isOpen = true;
  }

  /**
   * onInputBlur
   * @memberof OrganizationGroupSelectorComponent
   */
  public onInputBlur() {
    this.isOpen = false;
    this.query = '';
  }

  /**
   * toggleNodeSelected
   * @param {TreeNode} treeNode
   * @memberof OrganizationGroupSelectorComponent
   */
  public toggleNodeSelected(treeNode: TreeNode) {
    if (this.selectedValuesIndex[treeNode.id]) {
      this.onItemSelectedChange(treeNode, false);
    } else {
      this.onItemSelectedChange(treeNode, true);
    }
    this.onDone.emit(this.selectedIds);
  }

  /**
   * onItemSelectedChange
   * @param {TreeNode} selectedItem
   * @param {boolean} checked
   * @param {boolean} [checkParent=false]
   * @memberof OrganizationGroupSelectorComponent
   */
  public onItemSelectedChange(selectedItem: TreeNode, checked: boolean, checkParent: boolean = false) {
    this.toggleNode(selectedItem, checked);
    // Recursively select child orgs
    // checkParent flag is used when only parent orgs has to be checked.
    if (!isEmpty(selectedItem.children) && this.recursiveChildSelection && !checkParent) {
      selectedItem.children.forEach((item: TreeNode) => {
        this.onItemSelectedChange(item, checked);
      });
    }
    if (!this.recursiveParentSelection) {
      return;
    }
    // Check for parent org. Skip if the selected org is the root org
    if (this.treeRoot.id === selectedItem.id) {
      return;
    }
    const parentNode = this.findParent(selectedItem.id);
    const childIds = map(parentNode.children, 'id');
    const selectedIds = Object.values(this.selectedValuesIndex);
    // If current item is checked, select the parent if all siblings are also selected
    // If current item is unchecked, uncheck the parent if its checked
    if (checked && childIds.every((id: number) => selectedIds.includes(id))) {
      this.onItemSelectedChange(parentNode, checked, true);
    } else if (!checked && selectedIds.includes(parentNode.id)) {
      this.onItemSelectedChange(parentNode, checked, true);
    }
  }

  /**
   * searchValues
   * @param {string} query
   * @memberof OrganizationGroupSelectorComponent
   */
  public searchValues(query: string) {
    this.query = query;
    if (query?.length < this.MIN_CHAR_FOR_SEARCH) {
      return;
    }
    this.filteredTreeRoot = this.filterTreeNodes(this.treeRoot, query);
    if (this.filteredTreeRoot) {
      this.filteredTreeRoot.expanded = true;
    }
  }

  /**
   * removeSelectedValue
   * @param {number} value
   * @memberof OrganizationGroupSelectorComponent
   */
  public removeSelectedValue(value: number) {
    delete this.selectedValuesIndex[value]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
    const selectedIds = values(this.selectedValuesIndex);
    this.onDone.emit(selectedIds);
  }

  /**
   * getDisplayRoot
   * @returns {any}
   * @memberof OrganizationGroupSelectorComponent
   */
  public getDisplayRoot() {
    return this.query ? this.filteredTreeRoot : this.treeRoot;
  }

  /**
   * convertTree
   * @param {OrgTreeNode} orgTreeNode
   * @returns {TreeNode}
   * @memberof OrganizationGroupSelectorComponent
   */
  public convertTree(orgTreeNode: OrgTreeNode): TreeNode {
    return {
      id: orgTreeNode.value,
      text: orgTreeNode.data ? orgTreeNode.data.text : '',
      children: map(orgTreeNode.children, (childNode: OrgTreeNode) => {
        return this.convertTree(childNode);
      }),
    } as TreeNode;
  }

  /**
   * getTreeNodesById
   * @param {TreeNode} rootNode
   * @param {any} treeNodeIndex
   * @returns {any}
   * @memberof OrganizationGroupSelectorComponent
   */
  public getTreeNodesById(rootNode: TreeNode, treeNodeIndex: any = {}) {
    treeNodeIndex[rootNode.id] = rootNode;
    each(rootNode.children, (childNode) => {
      this.getTreeNodesById(childNode, treeNodeIndex);
    });
    return treeNodeIndex;
  }

  /**
   * filterTreeNodes
   * @param {TreeNode} item
   * @param {string} filterText
   * @returns {any}
   * @memberof OrganizationGroupSelectorComponent
   */
  public filterTreeNodes(item: TreeNode, filterText: string) {
    const isMatch = item.text.toLowerCase().includes(filterText.toLowerCase());
    const filteredChildren: TreeNode[] = item.children.reduce((children: TreeNode[], child: TreeNode) => {
      const newChild = this.filterTreeNodes(child, filterText);
      return newChild ? children.concat(newChild) : children;
    }, []);
    if (isMatch || filteredChildren.length > 0) {
      return Object.assign({}, item, {
        children: filteredChildren,
        expanded: true,
      });
    }
  }

  /**
   * toggleNode
   * @param {TreeNode} treeNode
   * @param {boolean} checked
   * @memberof OrganizationGroupSelectorComponent
   */
  private toggleNode(treeNode: TreeNode, checked: boolean) {
    if (checked) {
      if (this.singleSelection) {
        this.selectedValuesIndex = { [treeNode.id]: treeNode.id };
      } else {
        this.selectedValuesIndex[treeNode.id] = treeNode.id;
      }
    } else {
      delete this.selectedValuesIndex[treeNode.id]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
    }
    this.selectedIds = values(this.selectedValuesIndex);
  }

  /**
   * findParent
   * @param {number} selectedId
   * @returns {TreeNode}
   * @memberof OrganizationGroupSelectorComponent
   */
  private findParent(selectedId: number): TreeNode {
    const treeNodes: TreeNode[] = Object.values(this.treeNodesById);
    let parentNode: TreeNode;
    for (const treeNode of treeNodes) {
      if (treeNode.children && map(treeNode.children, 'id').includes(selectedId)) {
        parentNode = treeNode;
        break;
      }
    }
    return parentNode;
  }
}
