/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { FilterRuleConfig } from '@ws1c/intelligence-models';

/**
 * MultiNumberSelectorComponent
 * @export
 * @class MultiNumberSelectorComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-multi-number-selector',
  templateUrl: 'multi-number-selector.component.html',
  styleUrls: ['multi-number-selector.component.scss'],
  standalone: true,
  imports: [IntelligenceCommonModule],
})
export class MultiNumberSelectorComponent implements OnChanges {
  @Input() public selectedValues?: string[] = [];
  @Output() public onChange = new EventEmitter<number | number[]>();

  public selectedItems: GenericObject[] = [];

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof MultiNumberSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    // Set initial values
    if (changes.selectedValues) {
      this.patchValues();
    }
  }

  /**
   * wrapValue
   * @param {string} value
   * @returns {GenericObject}
   * @memberof MultiNumberSelectorComponent
   */
  public wrapValue(value: string): GenericObject {
    return { value };
  }

  /**
   * unwrapValue
   * @param {GenericObject} item
   * @returns {string}
   * @memberof MultiNumberSelectorComponent
   */
  public unwrapValue(item: GenericObject): string {
    return item.value;
  }

  /**
   * onSelectedItemsChange
   * @param {GenericObject[]} selectedItems
   * @memberof MultiNumberSelectorComponent
   */
  public onSelectedItemsChange(selectedItems: GenericObject[]) {
    const values: number[] = selectedItems.map((item: GenericObject) => Number(this.unwrapValue(item)));
    this.onChange.emit(values);
  }

  /**
   * patchValues
   * @private
   * @memberof MultiNumberSelectorComponent
   */
  private patchValues() {
    this.selectedItems = this.selectedValues.map((value) => {
      return { value, error: !FilterRuleConfig.NUMBER_PATTERN.test(value) };
    });
  }
}
